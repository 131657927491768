.pageContainer {
  padding-left: var(--container-padding-horizontal);
  padding-right: var(--container-padding-horizontal);
  padding-top: 24px;
  padding-bottom: 60px;
  transition: padding 0.8s;
}

.sectionTitle {
  text-align: start;
}

.buttonContainer {
  width: 120px;
}

.messageBubbleContainer {
  display: flex;
  flex-direction: column;
  width: 750px;
  row-gap: 24px;
}

.dateInputContainer {
  background-color: var(--grey-1);
  width: 150px;
  padding: 16px;
}

.textInput {
  margin-top: 24px;
}

.component {
  margin-top: 24px;
}

.messageInput {
  margin-top: 24px;
}

.componentContainer {
  margin: 20px 0;
}

.selectContainer {
  background-color: var(--light-purple-secondary);
  padding: 10px;
  padding-bottom: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.classificationCardContainer {
  display: flex;
  flex-direction: column;

  width: 20%;

  padding-top: 24px;
}
