.container {
  position: relative;
}

.icon {
  position: absolute;
  top: 16px;
  left: 10px;
}

.search {
  -webkit-appearance: none;
  -moz-appearance: none;

  height: 48px;
  padding-left: 32px;
  border: none;
  border-radius: 5px;
  width: 100%;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

.search::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

.search::-webkit-search-decoration,
.search::-webkit-search-cancel-button,
.search::-webkit-search-results-button,
.search::-webkit-search-results-decoration {
  display: none;
}

.search:placeholder-shown {
  text-overflow: ellipsis;
}
