.button {
  border-color: var(--active-link-purple);
  border-width: 1px;
  border-radius: 5px;
  width: 100%;
  height: 80px;
  padding-right: 42px;
  padding-left: 42px;
  background-color: transparent;
  cursor: pointer;
}

.selected {
  background-color: var(--light-blue-1);
}

.invalid {
  background-color: var(--light-red-2);
}

.title {
  color: var(--active-link-purple);
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
}

.childrenTransition {
  transition: visibility 0s, opacity 0.5s linear;
}

.childrenContainer {
  margin-top: 8px;
  transition: margin-top 0.5s linear;
}

.hide {
  visibility: hidden;
  opacity: 0;
}

.show {
  visibility: visible;
  opacity: 1;
}

.noDisplay {
  display: none;
}
