.leftSideBar {
  display: flex;
  flex-direction: column;
  background-color: var(--light-purple-secondary);
  border-radius: 5px;
  padding: 41px 16px 30px;
}

.selectTitle {
  text-align: left;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 10px;
}

.searchInput {
  margin-bottom: 16px;
}

.loading {
  align-self: center;
  margin-top: 10px;
}

.rightSideContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 5px;
  min-height: 212px;
  padding: 40px;
}

.diagnostic {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 16px;
}

.diagnosticKey {
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  margin-bottom: 4px;
}

.diagnosticValue {
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
}

.displayText {
  font-size: 14px;
  font-weight: 600;
  align-self: center;
}
