.assignedClassification {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  border-radius: 5px;

  padding-top: 40px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 32px;

  background-color: var(--light-purple-secondary);
}

.classificationText {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;

  margin-top: 8px;
  margin-bottom: 16px;
}

.saveButton {
  margin-top: 16px;
  margin-bottom: 16px;
}

.messagingContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  max-height: 65vh;

  border-radius: 5px;

  border: 1px solid var(--light-grey);
  background-color: var(--near-white);

  padding: 24px;

  overflow: hidden;
}

.scrollableSection {
  overflow-y: auto;

  margin-right: -24px;
  padding-right: 24px;

  display: flex;
  justify-items: flex-end;
  flex-direction: column;
}

.messagesHeader {
  margin-top: -24px;
  margin-left: -24px;
  margin-right: -24px;
}

.messageBubble {
  margin-top: 24px;
}

.bottomSpace {
  margin-bottom: 24px;
  width: 100%;
}

.spacing {
  width: 100%;
  flex: 1;
}

.loader {
  align-self: center;
  margin-top: 17px;
  margin-bottom: 17px;
}

.centeredActivityIndicator {
  align-self: center;
}
