.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  cursor: pointer;
}

input[type="radio"] {
  appearance: none;

  transition-duration: 0.25s;
  padding-right: 7px;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background: none;
  border: 2px solid var(--white);
  box-shadow: 0 0 0 1.5px var(--dark-grey);
  cursor: pointer;
}

input[type="radio"]:checked {
  background: var(--active-link-purple);
}

.labelText {
  transition-duration: 0.25s;
  padding: 4px 0 0 7px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

@media (hover: hover) {
  input[type="radio"]:not(:checked):hover + .labelText {
    color: var(--active-link-purple);
  }

  input[type="radio"]:not(:checked):hover {
    background: var(--active-link-purple-50);
  }
}
