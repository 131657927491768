@font-face {
  font-family: "Open Sans";
  src: local("Open Sans"),
    url("./assets/fonts/OpenSans-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Open Sans";
  src: local("Open Sans"),
    url("./assets/fonts/OpenSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Open Sans";
  src: local("Open Sans"),
    url("./assets/fonts/OpenSans-Bold.ttf") format("truetype");
  font-weight: 700;
}

html {
  --font-primary: "Open Sans", sans-serif;
  --white: #ffffff;
  --greenish-white: #f0fff8;
  --redish-white: #fff0f0;
  --active-link-purple: #2930da;
  --active-link-purple-50: #9597f5;
  --dark-purple: #22215f;
  --deep-purple: #111355;
  --light-purple: #88a6ba;
  --light-grey: #e1e1e8;
  --light-blue: #eaf6ff;
  --light-purple-secondary: #e3e3ef;
  --light-green: #18ab56;
  --light-green-2: #dfffdc;
  --light-red: #eb5757;
  --light-red-2: #ffdcdc;
  --light-grey: #dbdee9;
  --dark-grey: #3c3c3c;
  --blue-1: #2f80ed;
  --light-blue-1: #eaf6ff;
  --grey: #333333;
  --grey-1: #e1e1e8;
  --grey-2: #cfcfcf;
  --grey-3: #f0f0f0;

  --light-grey: #dddddd;
  --near-white: #fbfbfc;

  --background-color: #f5f5f8;

  --container-padding-horizontal: 112px;
  --max-width-left-side: 320px;
  --max-width-right-side: 1280px;

  --max-width: 1616px;
}

@media (min-width: 1840px) {
  :root {
    --container-padding-horizontal: calc((100vw - 1840px) / 2 + 112px);
  }
}

body {
  margin: 0;
  font-family: var(--font-primary), -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
}

input,
textarea,
button,
a {
  font-family: var(--font-primary), -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
