.classificationList {
  display: flex;
  flex-direction: column;

  margin-top: -4px;

  overflow-y: auto;
  overflow-x: hidden;
  max-height: 75vh;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: -16px;

  padding-right: 20px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 4px;
}

.loader {
  margin-top: 18px;
}
