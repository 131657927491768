.container {
  display: flex;
  justify-content: center;
}

.infoContainer {
  background-color: white;

  padding: 40px;
  margin-top: 80px;
  width: 540px;
  border-radius: 5px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: 19px;
  font-weight: 700;
  margin-bottom: 20px;
}

.errorMessage {
  font-size: 14px;
  margin-bottom: 16px;
}

.button {
  margin-top: 12px;
  text-decoration: none;
}
