.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  padding: 20px 15%;
}

.title {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  margin: initial;
}

.message {
  text-align: center;
}
