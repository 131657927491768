.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-left: var(--container-padding-horizontal);
  margin-right: var(--container-padding-horizontal);
  margin-top: 35px;
  margin-bottom: 35px;
}

.logo {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.headerItems {
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;
}

.headerButton {
  text-decoration: none;

  padding-left: 20px;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;

  color: var(--dark-purple);
}

.headerButtonSelected {
  font-weight: 700;
}

.logout {
  border: initial;
  background-color: initial;
  padding: initial;

  text-decoration: none;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;

  color: var(--dark-purple);
}

.logout:hover {
  cursor: pointer;
}
