.container {
  display: flex;
  flex-direction: row;
}

.paginationButton {
  display: flex;
  align-items: center;

  width: fit-content;
  height: 32px;
  border: none;
  border-radius: 5px;

  background-color: var(--light-purple-secondary);

  margin-right: 8px;
}

.paginationButton:hover {
  cursor: pointer;
}

.paginationButton:disabled {
  cursor: not-allowed;
}

.buttonTextContent {
  padding-left: 5px;
  padding-right: 5px;
}

.buttonImageContent {
  padding-left: 18px;
  padding-right: 18px;
}

.selectedButton {
  background: var(--dark-purple);
}

.selectedText {
  font-size: 12px;
  font-weight: 700;

  color: white;
}

.unselectedText {
  font-size: 12px;
  font-weight: 600;

  color: var(--grey);
}
