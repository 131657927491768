.classificationCard {
  min-height: 56px;
  height: fit-content;
  min-width: 100%;
  width: fit-content;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  border: none;
  border-radius: 5px;
  cursor: pointer;

  background-color: var(--white);
  text-align: left;

  padding-left: 12px;
  padding-right: 12px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 4px;
  margin-bottom: 4px;

  transition-timing-function: ease-out;
  -webkit-transition-timing-function: ease-out;
  transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
}

.selected {
  background-color: var(--light-purple-secondary);
}

.title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: var(--grey);
}

.number {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: var(--active-link-purple);

  padding-left: 5px;
}

@media (hover: hover) {
  .classificationCard:hover {
    box-shadow: 0 3px 0.5em var(--light-grey);
    transform: translateY(-2px);
    -webkit-transform: translateY(-2px);
  }

  .selected:hover {
    box-shadow: 0 3px 0.5em var(--light-purple);
  }
}
