.container {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 12px;
  padding-right: 12px;

  width: fit-content;
  border-radius: 8px;

  font-weight: 600;
  font-size: 12px;
}

.successStatus {
  background-color: var(--greenish-white);
  color: var(--light-green);
}

.dangerStatus {
  background-color: var(--redish-white);
  color: var(--light-red);
}
