.button {
  width: 100%;
  height: 48px;
  border: 1px solid var(--active-link-purple);
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
}

.unfilled {
  background-color: transparent;
  color: var(--active-link-purple);
}

.filled {
  background-color: var(--dark-purple);
  color: var(--white);
}

.button:disabled {
  opacity: 50%;
  cursor: not-allowed;
}

.loading {
  opacity: 1 !important;
}

.idle:enabled:hover {
  transform: translateY(-3px);
  -webkit-transform: translateY(-3px);
}
