.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: var(--light-purple-secondary);
  border: none;
  border-radius: 50%;
  color: var(--dark-purple);
  font-size: 14px;
  font-weight: 600;
}
