.container {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.paginationControlContainer {
  margin-top: 40px;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.currentEntries {
  margin-right: 16px;
  font-weight: 400;
  font-size: 14px;
  color: var(--grey);
}
