.container {
  border-radius: 5px;
  border: 1px solid var(--light-grey);
  background-color: var(--near-white);
  padding: 24px;
}

.assignedClassification {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  border-radius: 5px;

  padding-top: 40px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 32px;

  background-color: var(--light-purple-secondary);
}

.centeredActivityIndicator {
  align-self: center;
}

.select:first-child {
  margin-top: 0;
}

.select {
  margin: 8px 0;
}

.select:last-child {
  margin-bottom: 0;
}

.title {
  font-size: 20px;
  line-height: 27px;
  font-weight: 600;
  margin-bottom: 8px;
}

.messagesHeader {
  margin-top: 8px;
  padding: 0;
  background: none;
}

.subject {
  background: none;
  margin: 20px 0;
}

.message {
  margin: 20px 0;
}
