.accountHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 16px;
  border-radius: 5px;

  margin-bottom: 16px;

  background-color: var(--grey-1);
}

.accountIdText {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
}

.dateInputs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.dateInput {
  display: flex;
  flex-direction: row;

  align-items: center;
}

.dateInputMargin {
  margin-left: 15px;
}

.container {
  justify-self: center;
  width: 100%;
  margin-top: 25px;
}

.userInfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
