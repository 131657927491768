.container {
  flex: 1;

  display: flex;
  flex-direction: column;

  margin-top: 20px;
  margin-left: var(--container-padding-horizontal);
  margin-right: var(--container-padding-horizontal);
  margin-bottom: 72px;
}

.forwardBackBar {
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-between;

  margin-bottom: 10px;
}

.forwardOnly {
  justify-content: flex-end;
}

.textButtons {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;

  color: var(--active-link-purple);
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.backButtonImage {
  padding-right: 17px;
}

.forwardButtonImage {
  padding-left: 17px;
}

.layout {
  flex: 1;

  display: flex;
  flex-direction: row;

  justify-content: center;
  position: relative;
}

.leftSide {
  flex: 1;

  display: flex;
  flex-direction: column;

  margin-right: 16px;

  max-width: var(--max-width-left-side);

  position: relative;
  overflow: hidden;
}

.rightSide {
  flex: 4;

  display: flex;
  flex-direction: column;

  max-width: var(--max-width-right-side);
}

.mainArea {
  flex: 1;

  display: flex;
  flex-direction: column;

  max-width: var(--max-width);
}
