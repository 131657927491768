.header {
  background-color: var(--white);
  padding: 32px;
  align-items: center;
}

.row {
  display: flex;
  flex-direction: row;
}

.col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.spaceBetween {
  justify-content: space-between;
}

.columnGap {
  column-gap: 16px;
}

.messageSubject {
  font-size: 16px;
  font-weight: 700;
}

.accountId {
  font-size: 12px;
  font-weight: 400;
}
