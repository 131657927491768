.container {
  display: flex;
  justify-content: center;
}

.loginInfo {
  background-color: white;

  padding: 60px;
  margin-top: 80px;
  width: 512px;
  border-radius: 5px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.agentPortal {
  text-align: left;
  color: var(--grey);
  font-weight: 600;
  font-size: 18px;
}

.textInput {
  margin-top: 24px;
}

.signInButton {
  margin-top: 30px;
  text-decoration: none;
}

.signInText {
  color: white;
  font-weight: 600;
  font-size: 16px;
}
