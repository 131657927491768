.leftSideBar {
  display: flex;
  flex-direction: column;
  background-color: var(--light-purple-secondary);
  border-radius: 5px;
  padding: 41px 16px 30px;
}

.selectTitle {
  text-align: left;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 10px;
}

.searchInput {
  margin-bottom: 16px;
}

.rightSideContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--white);
  border-radius: 5px;
  min-height: 212px;
}

.displayText {
  font-size: 14px;
  font-weight: 600;
}

.loading {
  align-self: center;
  margin-top: 10px;
}
