.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 16px;
  width: 100%;
}

.incoming {
  justify-content: flex-start;
}

.outgoing {
  justify-content: flex-end;
}

.bubble {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  border: 1px solid var(--light-purple-secondary);
  border-radius: 5px;
  padding: 16px;
  width: 50%;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.timestamp {
  color: var(--grey);
  font-weight: 400;
  font-size: 10px;
}

.message {
  color: var(--grey);
  font-weight: 400;
  font-size: 12px;
  margin-block-start: 8px;
  margin-block-end: 0px;
  text-align: start;
  white-space: pre-wrap;
}

.rowGap {
  column-gap: 8px;
}

.labeledInfo {
  column-gap: 3px;
}

.label {
  color: var(--dark-purple);
  font-weight: 400;
  font-size: 10px;
  margin-block-start: 8px;
}

.value {
  color: var(--dark-purple);
  font-weight: 700;
  font-size: 10px;
  margin-block-start: 8px;
}

.attachmentsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.attachmentButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;

  border: solid 1px var(--grey-2);
  background-color: var(--grey-3);
  border-radius: 5px;
  padding-top: 18px;
  padding-bottom: 18px;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 10px;
  margin-bottom: 2px;

  cursor: pointer;
}

.attachmentName {
  font-size: 12px;
  font-weight: 600;
}
