.container {
  display: flex;
  flex-direction: column;
  padding: 0 81px;
}

.title {
  font-size: 20px;
  line-height: 27px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 16px;
}

.message {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 45px;
}
