.app {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.loadingContainer {
  display: flex;
  min-height: 100vh;
  min-width: 100vw;
  align-items: center;
  justify-content: center;
}
