.transition {
  transition: height 0.5s;
}

.childrenHeight {
  height: 108px;
}

.container {
  border: none;
  border-bottom: 1px solid var(--active-link-purple);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0 64px;
}

.customInput {
  border: none;
  width: 100%;
  outline: none;
  color: var(--active-link-purple);
  text-align: start;
  font-size: 14px;
  line-height: 19px;
  font-style: normal;
  font-weight: 600;
  text-align: center;

  /* override autofill color */
  box-shadow: inset 0 0 0 0 rgb(255 255 255 / 0%),
    inset 0 0 0 100px var(--light-blue-1);
  filter: none;
  background-clip: content-box;
}

.invalidCustomInput {
  box-shadow: inset 0 0 0 0 rgb(255 255 255 / 0%),
    inset 0 0 0 100px var(--light-red-2);
}

.customInput::placeholder {
  font-size: 14px;
  line-height: 19px;
}
