.contentContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--white);
  border-radius: 5px;
  min-height: 212px;
  padding: 40px;
}

.overviewContentContainer {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  border-radius: 5px;
  min-height: 212px;
  padding: 40px;
}

.overviewContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.overviewButtonContainer {
  display: flex;
  flex-direction: row;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  border-radius: 5px;
  min-height: 212px;
}

.leftHalf {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
}

.rightHalf {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-end;
}

.dwbuid {
  font-size: 20px;
  line-height: 27px;
  font-weight: 600;
  margin-bottom: 34px;
}

.textInput {
  width: 326px;
  margin-bottom: 16px;
}

.radioButton {
  margin-bottom: 16px;
}

.holderContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 8px;
}

.holderText,
.accountInformationText {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}

.holderText {
  margin-bottom: 16px;
}

.accountInformationText {
  margin-top: 20px;
  margin-bottom: 12px;
}

.amountText {
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  margin-top: 32px;
  margin-bottom: 16px;
}

.paymentAmountSelector {
  max-width: 384px;
  margin-bottom: 32px;
}

.enrollmentDate,
.overview {
  display: flex;
  flex-direction: column;
}

.overview {
  align-items: flex-start;
  margin-bottom: 16px;
}

.enrollmentDate {
  align-items: flex-end;
}

.enrollmentDateKey,
.overviewKey {
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  margin-bottom: 4px;
}

.enrollmentDateValue,
.overviewValue {
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
}

.displayText {
  font-size: 14px;
  font-weight: 600;
  align-self: center;
}

.enrollmentIndicator {
  max-width: 168px;
  margin-bottom: 24px;
}

.enrollAutopayButton {
  max-width: 336px;
  margin-top: 16px;
  margin-bottom: 2px;
}

.skipAutopayButton,
.removeAutopayButton {
  max-width: 336px;
  margin-top: 16px;
  margin-bottom: 2px;
}

.skipAutopayButton {
  margin-left: 24px;
}

.confirmationModalText {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}

.radioButtonGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 16px;
}

.autopayDisabledReason {
  margin: 16px 0px 0;
  background-color: var(--grey-2);
  padding: 26px 100px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
}
