.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.label {
  margin: 8px;
  color: var(--grey);
}

.input {
  background: var(--white);
  border: none;
  border-radius: 5px;
  padding-left: 16px;
  padding-right: 16px;
  height: 48px;
  color: var(--grey);
}

.input:focus {
  outline: none;
}
