.container {
  position: relative;
}

.messageInputContainer {
  display: flex;
}

.fileInput {
  width: 100%;
  padding-top: 24px;
  padding-bottom: 0px;
  padding-left: 24px;
  padding-right: 24px;

  box-sizing: border-box; /* Opera/IE 8+ */

  border-radius: 5px;
  border: 1px solid var(--light-grey);
  border-bottom: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  background-color: white;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  display: flex;
  overflow-x: auto;
}

.focusedFileInput {
  border-color: var(--active-link-purple);
}

.imageInfoContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  background-color: var(--background-color);
  border-radius: 6px;
  margin-right: 16px;
  max-width: 50%;
}

.imageNameContainer {
  position: relative;
  display: flex;
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 16px;
  padding-right: 20px;
  width: 80%;
}

.imageNameText {
  color: var(--dark-grey);
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  min-width: 0;
  overflow-wrap: break-word;
  text-align: left;
}

.removeIcon {
  background-color: white;
  height: 24;
  width: 24;
  border: 1px solid var(--light-grey);
  border-radius: 12px;
  cursor: pointer;

  position: absolute;
  right: -8px;
  top: -8px;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.messageInput {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 24px;
  padding-right: 120px;
  margin-top: 0px;

  box-sizing: border-box; /* Opera/IE 8+ */

  border-radius: 5px;
  border: 1px solid var(--light-grey);

  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  min-height: 122px;
  max-height: 230px;
  resize: vertical;
}

.messageInput:focus {
  border-color: var(--active-link-purple);
  outline: none;
}

.messageInput::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

.messageInputWithAttachments {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-top: 0px;
  min-height: 96px;
}

.attachmentPreview {
  max-width: 56px;
  min-width: 56px;
  min-height: 56px;
  height: 100%;
  border-radius: 5px;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.attachmentPreviewBackground {
  background-color: var(--light-blue);
}

.fileType {
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 4px;
}

.addImageBox {
  min-width: 56px;
  max-width: 56px;
  height: 56px;
  border-radius: 5px;
  background-color: var(--light-purple-secondary);
  border: 1px solid var(--active-link-purple);
  cursor: pointer;
}

.button {
  border: none;
  background-color: transparent;
  position: absolute;
  cursor: none;
}

.button:hover {
  cursor: pointer;
}

.sendButton {
  height: 40px;
  width: 40px;
  border-radius: 10px;
  background-color: var(--dark-purple);

  display: flex;
  justify-content: center;
  align-items: center;

  right: 60px;
  bottom: 10px;
}

.uploadButton {
  position: absolute;

  right: 16px;
  bottom: 14px;
}

.uploadButton:disabled {
  opacity: 40%;

  cursor: initial;
}

.hidden {
  display: none;
}

.spinner {
  position: absolute;

  height: 60px;
  width: 60px;

  right: 32px;
  bottom: 8px;
}
