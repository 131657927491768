.wrapper {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  overflow: hidden;
  width: 100%;
}

.container {
  width: 100%;
  border-collapse: collapse;
}

.tableRowContainer {
  background-color: white;
  border: none;
  padding: 0;
  display: flex;
  width: 100%;
  min-height: 60px;
  align-items: center;
}

.tableRowButton {
  cursor: none;
  transition: transform 0.1s;
  margin: 0;
  margin-top: 1px;
}

.tableRowButton:hover {
  cursor: pointer;
  background-color: var(--light-blue);
}

.tableRowContainer:not(:last-child) {
  border-bottom: 1px solid var(--light-grey);
}

.headerRow {
  background-color: var(--light-grey);
}

.headerTitleText {
  font-size: 12px;
  font-weight: 600;
  padding: 16px;
  text-align: left;
}

.entry {
  padding: 16px;
  display: flex;
  font-size: 12px;
  font-weight: 400;
  color: var(--grey);
}
