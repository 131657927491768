.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.input {
  padding: 12px;
  width: 100%;
  box-sizing: border-box;

  background-color: white;
  border: none;
  border-bottom: 1.5px solid var(--light-purple);
}

.input:focus {
  outline: none;
}

.textStyle,
::placeholder {
  font-weight: 400;
  font-size: 18px;

  color: var(--deep-purple);
}

.inputInvalid {
  border-bottom: 1.5px solid var(--light-red);
}

.inputInvalidMessage {
  color: var(--light-red);
  font-size: 15px;
  font-weight: 400;
  margin-top: 4px;
  text-align: left;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
