.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  outline: none;
  background-color: var(--white);
  border-radius: 5px;
  padding: 24px;
  width: 496px;
}

.modalWithCloseButton {
  padding-bottom: 52px;
}

.overlay {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.closeButton {
  align-self: flex-end;
  display: flex;
  border: initial;
  background-color: initial;
  padding: initial;
  margin-bottom: 24px;
}

.closeButton:hover {
  cursor: pointer;
}

.closeIcon {
  height: 18px;
  width: 18px;
}

@media (max-width: 599px) {
  .modal {
    width: 100%;
    border-radius: 0;
  }

  .closeButton {
    margin-right: 24px;
  }
}
