.footer {
  display: flex;

  margin-left: var(--container-padding-horizontal);
  margin-right: var(--container-padding-horizontal);
  margin-top: auto;
  margin-bottom: 35px;

  font-size: 14px;
  font-weight: 400;
  color: var(--grey);
}
