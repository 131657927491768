.contentContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--white);
  border-radius: 5px;
  min-height: 212px;
}

.validContainer {
  display: inherit;
  flex-direction: inherit;
  justify-content: inherit;
  padding: 50px 0 55px;
}

.lockIcon {
  height: 100px;
  object-fit: contain;
  margin-bottom: 17px;
}

.lockText,
.dateRegistered {
  font-size: 20px;
  font-weight: 600;
}

.unlockButton {
  align-self: center;
  background-color: initial;
  border: initial;
  margin-top: 10px;
  width: fit-content;
}

.unlockButton:hover {
  cursor: pointer;
}

.unlockText {
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  color: var(--active-link-purple);
}

.accountInfo {
  margin-bottom: 40px;
}

.button {
  align-self: center;
  margin-top: 16px;
  max-width: 336px;
}

.viewSecureMailButton {
  margin-top: 40px;
}

.displayText {
  font-size: 14px;
  font-weight: 600;
}

.loading {
  align-self: center;
  margin-top: 10px;
}

.enrollAutopayButtonDisabled {
  color: var(--dark-grey);
  background-color: var(--grey-2);
  border: none;
}

.autopayDisabledReason {
  margin: 16px 42px 0;
  background-color: var(--grey-2);
  padding: 26px 100px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
}

.sendPushSection {
  display: flex;
  flex: 1;
  align-self: stretch;
  align-items: center;
  justify-content: center;
}

.changeUsernameSection {
  display: flex;
  flex: 1;
  align-self: stretch;
  align-items: center;
  justify-content: center;
}

.changeUsernameForm {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: 24px;
  max-width: 336px;
  flex: 1;
  width: 100%;
}

.changeUsernameTitle {
  font-size: 20px;
  line-height: 27px;
  font-weight: 600;
  color: var(--dark-purple);
  margin-bottom: 8px;
}

.newUsernameInput {
  margin-bottom: 16px;
}

.submitNewUsernameButton {
  align-self: stretch;
}

.cancelUsernameChangeButton {
  align-self: stretch;
  margin-top: 16px;
}

.leftSideBar {
  display: flex;
  flex-direction: column;
  background-color: var(--light-purple-secondary);
  border-radius: 5px;
  padding: 41px 16px 30px;
}

.selectTitle {
  text-align: left;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 10px;
}

.searchInput {
  margin-bottom: 16px;
}
