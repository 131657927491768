.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 55px;
  border-radius: 5px;
  background-color: var(--light-green-2);

  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  color: black;
}

.notEnrolled {
  background-color: var(--light-red-2);
}
