.selectContainer {
  width: 100%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: var(--white);
}

.disabledContainer {
  opacity: 0.5;
}

.selectContainer img {
  top: 20px;
  right: 20px;
  pointer-events: none;
  margin-left: 8px;
}

.content {
  position: relative;
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding: 14px 12px;
}

.select {
  outline: none;
  top: 0px;
  left: 0px;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 1;
  -webkit-appearance: none;
  color: transparent;
  cursor: pointer;
  border: 2px solid var(--white);
  transition: border 0.25s ease;
  border-radius: 5px;
}

.select:disabled {
  cursor: default;
}

.selectedLabel {
  font-family: inherit;
  font-size: inherit;

  font-family: inherit;
  font-size: 14px;
  line-height: 19px;
}

.options {
  color: black;
}

@media (hover: hover) {
  .select:hover:enabled {
    border-color: var(--active-link-purple);
  }
}
