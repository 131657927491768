.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-bottom: 16px;
  width: 100%;
}

.minPaymentDueButton,
.button {
  width: 100%;
}

.minPaymentDueButton {
  margin-right: 16px;
}
